import classnames from 'classnames';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { ReactElement, useEffect, useState } from 'react';
import { SearchState } from 'react-instantsearch-core';

import {
  findColorDimension,
  findStringDimension,
  findVariantBySelectedDimensionOptions,
  getCardFallbackImage,
  getDimensionOption,
  getProductPriceRanges,
  getProductV4LinkParams,
  selectStartingVariant,
} from './utils';
import { getProductCardTrackingInfo } from './utils/getProductCardTrackingInfo';
import { MarkdownPromoContainer } from 'components/MarkdownPromoContainer/MarkdownPromoContainer';
import { MarkdownDisplayMode } from 'components/MarkdownText/MarkdownText';
import ProductCardColors from 'components/ProductGrid/ProductCard/ProductCardColors/ProductCardColors';
import ProductCardDetails from 'components/ProductGrid/ProductCard/ProductCardDetails/ProductCardDetails';
import ProductCardImage from 'components/ProductGrid/ProductCard/ProductCardImage/ProductCardImage';
import { isVIPBrand } from 'explorer/lib/utils/brand';

import { AlgoliaSortOptionEnum } from 'lib/algolia/utils';
import { trackProductClicked } from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';
import { useTrackEventOnScreenDuration } from 'lib/hooks/analytics/useTrackEventOnScreen';
import { MarkdownVariantDetails } from 'lib/hooks/promo/usePromoEligible';
import { ProductLinkProps } from 'lib/links/_productLink';
import { createDatadogElementSelector } from 'lib/utils/browser';
import { buildGtmAttributesForProduct } from 'lib/utils/tracking';
import { getVariantPriceInformation } from 'lib/variant/price';

import {
  ColorDimensionOption,
  OptionChangeHandler,
  ProductCardData,
  ProductCardVariant,
  ProductCardVariantDimensionOption,
  ProductThumbnailClickHandler,
  StringDimensionOption,
} from './types';
import { BookmarkByProductSid } from 'types/app';
import { AttributionInput, Image } from 'types/generated/api';

import styles from './ProductCard.module.scss';

import sharedStyles from '../sharedProductCardStyles.module.scss';

type ProductCardProps = {
  attribution?: AttributionInput;
  bookmarksByProductSid: BookmarkByProductSid[];
  className?: string;
  'data-insights-object-id'?: string;
  'data-insights-query-id'?: string;
  'data-test-id'?: string;
  hideBookmarkButton?: boolean;
  index: number;
  initialSelectedDimensionOptions?: ProductCardVariantDimensionOption[];
  isSizeFilterSelected?: boolean;
  lazyLoadImage?: boolean;
  onProductLinkClick?: () => void;
  onProductThumbnailClick?: ProductThumbnailClickHandler;
  productCardData: ProductCardData;
  searchState?: SearchState;
  sortValue?: AlgoliaSortOptionEnum;
  trackingIdentifier?: string;
};

export const DATADOG_TEST_ID = 'ProductCard-container';

const ProductCardWithData = ({
  attribution: attribution,
  bookmarksByProductSid,
  className,
  hideBookmarkButton,
  index,
  initialSelectedDimensionOptions,
  isSizeFilterSelected,
  lazyLoadImage,
  onProductLinkClick,
  onProductThumbnailClick,
  productCardData,
  searchState,
  sortValue,
  trackingIdentifier,
  ...rest
}: ProductCardProps): ReactElement | null => {
  const {
    averageRating,
    brand,
    brandSlug,
    colorFamilyCode,
    colorFamilyName,
    dimensions,
    familySlug,
    hierarchicalCategories,
    imageUrl,
    inStock,
    isPromoted,
    productSid,
    productSlug,
    reviewCount,
    selectedOptions,
    sid: variantIdFromSizeFilter,
    title,
    variantTitle,
    variants,
  } = productCardData;
  /* Dimension options are taken from dimensions.
   * A plpGroup may have 0, 1, or 2 dimensions
   * Product cards currently support a COLOR or STRING as a primary dimension
   * If a plpGroup has two dimensions, the first must be COLOR
   */
  const colorDimension = dimensions.length
    ? findColorDimension(dimensions)
    : undefined;

  const stringDimension = dimensions.length
    ? findStringDimension(dimensions)
    : undefined;

  const colorDimensionIsPrimary = !!colorDimension?.primary;
  const startingVariant = selectStartingVariant(
    variants,
    dimensions,
    sortValue,
    searchState,
    selectedOptions
  );
  let startingSelectedDimensionOptions: ProductCardVariantDimensionOption[] =
    [];
  // If we have a starting variant we already know our selectedDimensionOptions
  if (startingVariant?.values) {
    startingSelectedDimensionOptions = startingVariant.values;
  } else if (initialSelectedDimensionOptions) {
    startingSelectedDimensionOptions = initialSelectedDimensionOptions;
  }
  // Auto select primary dimension if it is COLOR
  else if (colorDimension && colorDimensionIsPrimary) {
    const indexForSelectedColor = colorDimension?.values?.findIndex(
      value =>
        value.colorFamily === colorFamilyCode || value.value === colorFamilyName
    );

    startingSelectedDimensionOptions = [
      {
        label: colorDimension.label,
        name: colorDimension.name,
        value:
          colorDimension?.values?.[
            indexForSelectedColor === -1 ? 0 : indexForSelectedColor
          ]?.value,
      },
    ];
  }

  const [selectedDimensionOptions, setselectedDimensionOptions] = useState<
    ProductCardVariantDimensionOption[]
  >(startingSelectedDimensionOptions);

  const getSelectedColorDimension = (
    newSelectedDimensionOptions: ProductCardVariantDimensionOption[]
  ): ColorDimensionOption | undefined => {
    if (!colorDimension) {
      return undefined;
    }

    const newSelectedColor = find(
      newSelectedDimensionOptions,
      option => option.name === colorDimension.name
    );

    return newSelectedColor
      ? (getDimensionOption(
          dimensions,
          newSelectedColor
        ) as ColorDimensionOption)
      : undefined;
  };

  const getSelectedStringDimension = (
    newSelectedDimensionOptions: ProductCardVariantDimensionOption[]
  ): StringDimensionOption | undefined => {
    if (!stringDimension) {
      return undefined;
    }
    const newSelectedString = find(
      newSelectedDimensionOptions,
      option => option.name === stringDimension.name
    );
    return newSelectedString
      ? getDimensionOption(dimensions, newSelectedString)
      : undefined;
  };

  const selectedColor = getSelectedColorDimension(selectedDimensionOptions);
  const selectedString = getSelectedStringDimension(selectedDimensionOptions);
  const selectedPrimaryDimension = colorDimensionIsPrimary
    ? selectedColor
    : selectedString;

  // Hooks
  const [selectedVariant, setSelectedVariant] = useState<
    ProductCardVariant | undefined
  >(startingVariant);

  const getDefaultImage = () => ({
    url:
      // If a primary dimension exists, it will contain photos for that dimension's options.
      selectedPrimaryDimension?.preview?.images?.[0] ??
      // If no dimensions exist, we dip into this algolia record's galleryImages.
      imageUrl ??
      // Fallback case, look in dimension preview images
      getCardFallbackImage(productCardData),
  });

  const [selectedImage, setSelectedImage] = useState<Image>(getDefaultImage());

  const handleUpdatingProductLinkProps = (
    variantSid?: string,
    currentSelectedDimensionOptions?: ProductCardVariantDimensionOption[]
  ) => {
    const updatedProductLinkParams = getProductV4LinkParams(
      productLinkProps,
      currentSelectedDimensionOptions,
      colorDimension
    );

    setProductLinkProps(props => ({
      ...props,
      queryStringParams: updatedProductLinkParams.queryStringParams,
      selectedVariant: variantSid
        ? {
            selectedOptions: selectedDimensionOptions,
            sid: variantSid,
          }
        : undefined,
    }));
  };

  const handleOptionChange: OptionChangeHandler =
    newSelectedDimensionOption => {
      const newSelectedDimensionOptions: ProductCardVariantDimensionOption[] = [
        ...selectedDimensionOptions,
      ];
      const replaceOptionIndex = findIndex(
        newSelectedDimensionOptions,
        option => option.name === newSelectedDimensionOption.name
      );
      if (replaceOptionIndex !== -1) {
        // overwrite existing option
        newSelectedDimensionOptions[replaceOptionIndex] =
          newSelectedDimensionOption as ProductCardVariantDimensionOption;
      } else {
        // add new option
        newSelectedDimensionOptions.push(
          newSelectedDimensionOption as ProductCardVariantDimensionOption
        );
      }

      // A variant may not be selected, but if the selectedDimensionOption for color changed,
      // update the selectedImage
      handleUpdatingImage(newSelectedDimensionOptions);
      setselectedDimensionOptions(newSelectedDimensionOptions);

      const variantOptionValue: ProductCardVariant | undefined =
        findVariantBySelectedDimensionOptions(
          variants,
          newSelectedDimensionOptions
        );

      handleUpdatingProductLinkProps(
        variantOptionValue?.sid,
        newSelectedDimensionOptions
      );

      // a variant is selected
      if (newSelectedDimensionOptions.length === dimensions.length) {
        setSelectedVariant(variantOptionValue);
      }
    };

  // Update image based on selectedDimensionOptions
  const handleUpdatingImage = (
    newSelectedDimensionOptions: ProductCardVariantDimensionOption[]
  ) => {
    const newSelectedColor = getSelectedColorDimension(
      newSelectedDimensionOptions
    );
    if (newSelectedColor) {
      const previewImage = newSelectedColor?.preview?.images?.[0];
      setSelectedImage({ url: previewImage });
    } else {
      setSelectedImage(getDefaultImage());
    }
  };

  // handles product link
  const [productLinkProps, setProductLinkProps] = useState<ProductLinkProps>({
    brandSlug,
    children: undefined,
    familySlug,
    hierarchicalCategories,
    includeVariantIdAttribute: isSizeFilterSelected,
    productId: productSid,
    productSlug,
    // queryStringParams, - this prop is used, but not initialized, so just keeping it commented out for awareness
  });

  // On mount, set variantSid or color as a query param to ProductLink if they exist
  useEffect(() => {
    const currentSelectedVariantSid = isSizeFilterSelected
      ? variantIdFromSizeFilter
      : selectedVariant?.sid;

    const startingProductLinkParams = getProductV4LinkParams(
      productLinkProps,
      selectedDimensionOptions,
      colorDimension
    );

    const newLinkProps = {
      ...productLinkProps,
      includeVariantIdAttribute: isSizeFilterSelected,
      queryStringParams: startingProductLinkParams?.queryStringParams,
      selectedVariant: currentSelectedVariantSid
        ? {
            selectedOptions: selectedDimensionOptions,
            sid: currentSelectedVariantSid,
          }
        : undefined,
    };

    if (!isEqual(productLinkProps, newLinkProps)) {
      setProductLinkProps(newLinkProps);
    }

    handleUpdatingImage(selectedDimensionOptions);
  }, [
    colorDimension,
    hierarchicalCategories,
    isSizeFilterSelected,
    productLinkProps,
    selectedDimensionOptions,
    selectedVariant,
    variantIdFromSizeFilter,
  ]);

  const handleTrackingProductOnClick = () => {
    const trackingInfo = getProductCardTrackingInfo(productCardData);
    trackProductClicked({ ...trackingInfo, trackingIdentifier });
  };

  const { currencyCode, price } = getVariantPriceInformation(
    selectedVariant,
    productCardData
  );

  const productPriceRanges = getProductPriceRanges(variants);
  const selectedVariantPrices = {
    compareAtPrice: selectedVariant?.compareAtPrice,
    price: selectedVariant?.price,
  };

  const gtmAttributes = buildGtmAttributesForProduct({
    attribution,
    maxPrice: productPriceRanges?.priceRange?.maxPrice,
    minPrice: productPriceRanges?.priceRange?.minPrice,
    productId: productSid,
    productSlug,
  });

  const refTracker = useTrackEventOnScreenDuration(
    AnalyticsEvent.PRODUCT_LISTING_VISIBLE,
    {
      brand_slug: brandSlug,
      family_slug: familySlug,
      is_promoted: isPromoted,
      position: index,
      product_id: productSid,
      product_slug: productSlug,
      trackingIdentifier,
      variant_max_price: productPriceRanges?.priceRange?.maxPrice,
      variant_min_price: productPriceRanges?.priceRange?.minPrice,
    }
  );

  const bookmarkSelectedOptions = isEmpty(selectedDimensionOptions)
    ? []
    : selectedDimensionOptions.map(selectedDimension => ({
        name: selectedDimension.name,
        value: selectedDimension.value,
      }));
  const datadogSelectorAttribute =
    index === 0 && createDatadogElementSelector(DATADOG_TEST_ID);

  const markdownVariantDetails: MarkdownVariantDetails = (variantData => ({
    ...variantData,
    isDiscountable: !!variantData.isDiscountable,
    isMarketplace: variantData.familySlug === 'marketplace',
  }))(productCardData);

  const productCategoryHierarchies = (
    productCardData?.productCategories ?? []
  ).map(categoryArray => categoryArray.join(' > '));

  // Check if any ProductVariant is in stock
  const productVariantsInStock = variants.some(
    variant => variant.inStock === true
  );

  /** Makes sure ProductVariant's inStock matches product level inStock value.
   * Otherwise, default to true, which disables OOS overlay
   */
  const effectiveStock = productVariantsInStock || inStock;

  // algolia position starts from 1
  // https://www.algolia.com/doc/guides/sending-events/implementing/connectors/google-tag-manager/#required-html-attributes-for-hits
  const position = index + 1;
  return (
    <div
      className={classnames(sharedStyles.root, className)}
      {...gtmAttributes}
      {...datadogSelectorAttribute}
      data-insights-is-promoted={isPromoted}
      data-insights-object-id={rest['data-insights-object-id']}
      data-insights-position={position}
      data-insights-query-id={rest['data-insights-query-id']}
      data-test-id={rest['data-test-id']}
      ref={refTracker}
    >
      <ProductCardImage
        bookmarkSelectedOptions={bookmarkSelectedOptions}
        bookmarksByProductSid={bookmarksByProductSid}
        handleTrackingProductOnClick={handleTrackingProductOnClick}
        hideBookmarkButton={hideBookmarkButton}
        image={selectedImage}
        inStock={effectiveStock}
        isLazyLoad={lazyLoadImage}
        onProductLinkClick={onProductLinkClick}
        onProductThumbnailClick={onProductThumbnailClick}
        price={price ?? undefined}
        productCardData={productCardData}
        productCardIndex={index}
        productLinkProps={productLinkProps}
        variantSid={selectedVariant?.sid}
      />
      <ProductCardDetails
        averageRating={averageRating}
        brand={brand}
        currencyCode={currencyCode || ''}
        handleTrackingProductOnClick={handleTrackingProductOnClick}
        hasSelectedColor={selectedColor !== undefined}
        isVipBrand={isVIPBrand(productCardData.brandEntity?.subscriptionTier)}
        productLinkProps={productLinkProps}
        productPriceRanges={productPriceRanges}
        reviewCount={reviewCount}
        selectedVariantPrices={selectedVariantPrices}
        title={variantTitle || title}
      />
      <MarkdownPromoContainer
        markdownDisplayMode={MarkdownDisplayMode.SUMMARY}
        offerDetails={productCardData.offerDetails}
        productCategoryHierarchies={productCategoryHierarchies}
        productVariantDetails={markdownVariantDetails}
      />
      {colorDimension && colorDimension?.values?.length > 1 && (
        <ProductCardColors
          className={styles.colorSelector}
          colors={colorDimension.values}
          handleOptionChange={handleOptionChange}
          optionName={colorDimension.name}
          selectedColor={selectedColor}
        />
      )}
    </div>
  );
};

export const ProductCard = (props: ProductCardProps): ReactElement | null => {
  if (isEmpty(props?.productCardData)) {
    return null;
  }
  return <ProductCardWithData {...props} />;
};

export default ProductCard;
